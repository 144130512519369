import Vue from 'vue/dist/vue.esm'


var slider = new Vue({
    el: ".homepage-slider",
    data: {
        slides: gon.slides,
        current: 0,
    },
    computed: {
        title() {
            return this.slides[this.current].title;
        },
        description() {
            return this.slides[this.current].description;
        },
        image() {
            return this.slides[this.current].image_url;
        }
    },
    methods: {
        next() {
            if(this.current!=this.slides.length-1){
                this.current+=1;
            }
        },
        prev() {
            if(this.current!=0){
                this.current-=1;
            }
        },
    }
});
